import React from 'react';
import exercise_data from '../scripts/exercise_data';
import campaign_data from '../scripts/campaign_data';


import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import TopicIcon from '@mui/icons-material/Topic';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Exercise from './Exercise';
import CircularProgress from '@mui/material/CircularProgress';
// import { TextField } from '@mui/material';
// import { tabsListUnstyledClasses } from '@mui/base';
// import { toHaveStyle } from '@testing-library/jest-dom/matchers';

export default class Campaigns extends React.Component {

  constructor(props) {
    super(props);

    // console.log("Campaign =================================================");

    // this.edaRef = React.createRef();

    // this.exercise_data = exercise_data();
    this.campdata = campaign_data();

    // console.log("Exercise_data: ", this.exercise_data);

    // console.log("Campaigns level", props);

    if (props.level) this.level = JSON.parse(props.level);

    // console.log("Campaigns level", this.level);

    this.state = {
      loaded: false,
      open: false,
    }
  }

  resetOpen() {
    let open = [];
    for (let i in this.props.campaigns) {
      open.push(false);
    }

    // console.log("resetOpen:", open);

    this.setState({
      open: open,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps === this.props) return;

    // console.log("Campaigns update ++++++++", this.props);

    // this.resetOpen();

    if (this.props.level) this.level = JSON.parse(this.props.level);
  }

  componentDidMount() {
    // console.log("componentDidMount resetOpen");
    this.resetOpen();
  }

  handleClick(i) {
    // console.log("Campaign handleClick state:", this.state);

    // deep copy 
    let s = Array.from(this.state.open);

    s[i] = !s[i];

    //this.state.open[i] = !this.state.open[i];

    // console.log("handleClick:", s);

    this.setState({ open: s });

  }

  exerciseSelected(campname, exname) {
    console.log("Campaign selected: ", campname, "exercise:", exname);
    this.setState({ exerciseSelected: exname, campaignSelected: campname });

    this.props.exerciseSelectedCb(campname, exname);
  }

  // exerciseFinished() {
  //   this.setState({ exerciseSelected: false });
  // }

  // onEda(gsr, acc) {
  //   console.log("Campaign: onEda");

  //   // passing further to Exercise
  //   if(this.edaRef.current) this.edaRef.current.onEda(gsr, acc)
  // }

  render() {

    if (!this.level) {
      console.log("No level info provided to Capmaign");
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress />
        </Box>);
    }

    let c = [];

    // console.log("campaigns available for this user: ", this.props.campaigns);

    for (let i in this.props.campaigns) {
      let campname = this.props.campaigns[i];
      let camp = false;

      if(!(campname in this.campdata)) {
        console.log("Error unknown campaign", campname);
        continue;
      }

      camp = this.campdata[campname];

      // console.log("Found campaign", camp);

      // for (let j in this.campaign_data) {
      //   if (this.campaign_data[j].id === campid) {
      //     camp = this.campaign_data[j];
      //     break;

      //   }
      // }

      // if (!camp) {
      //   console.log("Error unknown campaign", campid);
      //   continue;
      // }

      c.push(
        <ListItemButton key={campname} onClick={this.handleClick.bind(this, i)}>
          <ListItemIcon key={campname + "icon"} >
            <TopicIcon key={campname + "icon2"} />
          </ListItemIcon>
          <ListItemText key={campname + "label"} primary={camp.label} />

          {this.state.open[i] ? <ExpandLess /> : <ExpandMore />}

        </ListItemButton>

      );

      let notReached = 0;

      // console.log("///////////////// CAMPID", campid);
      // console.log(camp.exercises);

      let e = [];
      for (let exname in camp.exercises) {

        // console.log(exname);

        let ex = camp.exercises[exname];

        // console.log("EXNAME:", exname);

        // if(exname not in this.exercise_data) {
        //   console.log("ERRR");
        // }

        // let ex = this.exercise_data[exname];

        // if(ex === undefined) {
        //   console.error("ERROR WRONG exercise name:", exname);
        //   continue;

        // }

        //console.log("EX", ex, "CAMPID", campid, "LEVEL", this.level);

        let mylevel;
        if (campname === "experimental") {
          mylevel = 100;
        } else if (campname === "spider") {
          mylevel = 100;
        } else {
          if (!this.level) continue;
          if (!this.level[campname]) continue;
          mylevel = this.level[campname].level;

        }


        // console.log("MYLEVEL", mylevel, this.level);

        let label = ex.label;
        let seclabel = false;
        seclabel = `level ${ex.level}`;

        let available = true;
        // if (mylevel < ex.level && (this.props.user !== "root")) {
        if (mylevel < ex.level) {
          notReached += 1;

          if (notReached == 1) {
            available = false;
            seclabel = " you have not reached this level";
          } else {
            label = "..."
            seclabel = "";
            continue;

          }
        }

        if(ex.inactive == true) continue;

        e.push(
          <ListItemButton disabled={!available} key={campname + "_" + ex.label} sx={{ pl: 5 }} onClick={this.exerciseSelected.bind(this, campname, exname)}>
            <ListItemIcon>
              <PlayCircleIcon />
            </ListItemIcon>
            <ListItemText primary={label} secondary={seclabel} />
          </ListItemButton>

        )
      }

      c.push(
        <Collapse key={campname + "bb"} in={this.state.open[i]} timeout="auto" unmountOnExit>
          <List key={campname + "_list"} disablePadding>
            {e}
          </List>
        </Collapse>
      )
    }

    return (
      <Box maxWidth='90%' margin={3} alignItems="center"
        justifyContent="center" display="flex">

        <List
          sx={{ width: '100%', maxWidth: 500, bgcolor: 'background.paper' }}
          component="nav"
          subheader={
            < ListSubheader  >
              <Typography variant="h6">
                Your exercises
              </Typography>
            </ListSubheader >
          }
        >
          {c}
        </List>


      </Box>
    );

  }
}
