import React, { Component } from 'react';

import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import 'chartjs-plugin-annotation';
import adjust_timesteps from './scripts/adjust_timesteps';

// import DataTable from 'react-data-table-component';
// import { data } from 'jquery';

// import Chart from 'chart.js/auto';


import WaveResultPlot from './WaveResultPlot';
import ScoreBox from './ScoreBox';
import ResultChart from './ResultChart';

// import { CalcScore } from './scripts/calc_score.js';
import { CalcSessionStats } from './scripts/calc_sessionstats.js';

import campaign_data from './scripts/campaign_data';
import { MyContext } from './MyContext';


export default class WaveResultCard extends Component {
  static contextType = MyContext;

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      deleteDialogOpen: false
    }

    this.campdata = campaign_data();

    let ses = this.props.session;


    if (!ses.data) {
      console.error("No data in session");  
      return;
    }

    ses.data.eda = adjust_timesteps(ses.data.eda);
    if (ses.data.fineacc && ses.data.fineacc.length > 0) {
      ses.data.fineacc = adjust_timesteps(ses.data.fineacc);
    }


    //console.log("Wave const");

  }

  componentDidUpdate(prevProps) {
    if (this.props === prevProps) return;

    if(this.props.session.data.ts === prevProps.session.data.ts) return;

    console.log("WaveResultCard PROPS CHAGNED ******************************************", this.props, prevProps);

    // compare the contents of the props
    

  }

  componentDidMount() {
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleClickEda() {
    this.setState({ open: true, openwhat: "eda" });
  }

  async handleClickDownload() {
    const aTag = document.createElement('a');

    let ses = this.props.session;
    // console.log("DATA:", ses);

    let ts, gsr;

    // console.log("YY eda", ses.data.eda);

    ses.data.eda = adjust_timesteps(ses.data.eda);

    // console.log("XX eda", ses.data.eda);

    if (ses.data.fineacc && ses.data.fineacc.length > 0) {
      ses.data.fineacc = adjust_timesteps(ses.data.fineacc);
    }

    let res = [];

    let i=0;
    for ([ts, gsr] of ses.data.eda) {

      console.log("TS", ts, gsr);

      if (ses.data.fineacc && ses.data.fineacc.length > 0) {

        // console.log("FINEACC", ses.data.fineacc);

        let sacc = " ";

        if (ses.data.fineacc.length > i + 1) sacc = ses.data.fineacc[i][1];

        res.push("" + ts + " " + gsr + " [" + sacc + "]\n");

      } else if (ses.data.acc) {

        let sacc = "";
        sacc = "" + (ses.data.acc[i][1]);
        console.log(ts, gsr, ses.data.acc[i][1]);

        res.push("" + ts + " " + gsr + " " + sacc + "\n");

      } else {

        res.push("" + ts + " " + gsr + "\n");

      }

      i += 1;
    }

    const blob = new Blob(res, { type: 'text/plain' });

    let dev = "";
    if (ses.device) dev = "_" + ses.device + "_";

    console.log("DEV", dev);

    let fname = ses.user + "_" + ses.campaign + "_" + ses.exercise + "_" + this.date + "_" + this.time + dev + ".txt";

    //aTag.href = data;
    aTag.href = URL.createObjectURL(blob);
    aTag.download = fname;
    aTag.click();


    let res_events = [];
    if (ses.data.events) {
      if (ses.data.events.length > 0) {
        let e = null;
        for ([ts, e] of ses.data.events) {

          res_events.push("" + ts + " " + e + "\n");
        }

        let fname2 = ses.user + "_" + ses.campaign + "_" + ses.exercise + "_" + this.date + "_" + this.time + dev + "events.txt";
        const blob2 = new Blob(res_events, { type: 'text/plain' });

        aTag.href = URL.createObjectURL(blob2);
        aTag.download = fname2;
        aTag.click();

      }

    }


  }


  async handleClickTree() {

    this.setState({ open: true, openwhat: "loading" });

    console.log("BACKEND findcreature");


    let resp = await fetch('/api/findcreature', {
      method: 'POST',
      body: JSON.stringify({ creatureId: this.treeId }),
      headers: {
        'Content-Type': 'application/json'
      }
    })

    let d = await resp.json();

    //console.log("ClickTree fetch result", d);

    this.treeImg = d.img;

    this.setState({ open: true, openwhat: "tree" });

  }

  async deleteSession() {

    console.log("BACKEND DeleteSession:", this.props.session);

    let resp = await fetch('/api/deleteexercise', {
      method: 'POST',
      body: JSON.stringify({ ts: this.props.session.ts, user: this.props.session.user }),
      headers: {
        'Content-Type': 'application/json'
      }
    })

    let d = await resp.json();

    //if(this.props.reload.current) this.props.reload.current();
    this.context.reload();

  }

  handleDeleteClickOpen = () => {
    this.setState({ deleteDialogOpen: true });
  };

  handleDeleteClose = () => {
    this.setState({ deleteDialogOpen: false });
  };



  render() {

    console.log("WaveResultCard render");
    let ses = this.props.session;

    if (!ses) return null;
    if(ses.data.eda.length<1) return;

    let calcStats = new CalcSessionStats();
    let sessionStats = calcStats.calc_stats(ses);

    console.log("ses", ses);
    // console.log("sessionStats", sessionStats);

    let feedback = ses.data.feedback;
    console.log("feedback", feedback);

    var date = new Date(ses.ts).toLocaleDateString("en-US");
    var time = new Date(ses.ts).toLocaleTimeString("en-US");

    this.date = date;
    this.time = time;

    let dur = ses.data.eda[ses.data.eda.length - 1][0] - ses.data.eda[0][0];
    dur = Math.floor(dur / 1000);

    let treepts = false;
    if (ses.data.creature) {
      let creature = ses.data.creature;
      if (creature.type === "tree") {
        this.treeId = creature.creatureId;
        if (creature.data.size >= 50) treepts = 1;
      }

      if (creature.type === "flower") {
        this.treeId = creature.creatureId;
        if (creature.data.size >= 50) treepts = 1;
      }
    }

    let scoreBoxData = {
      decr: sessionStats.decr,
      sustain: sessionStats.sustain,
      relaxpts: sessionStats.relaxpts,
      tree: treepts
    }


    //feedback = "dklkd sdaskl dsldkaslk dasldkasl dasldkalk dasldkal sdlkasldka adlakdla slasldka dasldkalk lkllk"
    var feedback_short = "";
    if (feedback && feedback.length > 20) feedback_short = feedback.substring(1, 20) + "...";
    else {
      feedback_short = feedback;
    }

    if (!ses.campaign) return;

    let ex = this.campdata[ses.campaign].exercises[ses.exercise];

    // console.log("USER", this.context.userRecord);

    let showEda = false;
    let u = this.context.userRecord;
    if (u.isRoot || u.showEda) showEda = true;

    let difficulty = "";
    if (ses.params)
      if (ses.params.difficulty) {
        console.log(" ====", ses.params)
        difficulty = ses.params.difficulty;
      }

    return (
      <Card elevation={5} >

        <CardContent>

          <Typography variant="h6" color="text.primary">
            {date} {time} ({dur}s)
          </Typography>

          <Typography variant="body2" color="text.primary">
            {this.campdata[ses.campaign].label} / {ex.label} {difficulty} ({ses.device})
          </Typography>

        </CardContent>

        {/* <CardContent> */}
        <WaveResultPlot key={ses._id} scores={ses.data.scores} />

        {/* </CardContent> */}

        {/* <CardContent> */}
        <ScoreBox data={scoreBoxData}></ScoreBox>

        {(feedback && feedback.length > 1) &&
          <Paper sx={{ m: "auto", width: 200, background: "lightyellow" }} elevation={1} background="yellow">
            <Typography variant="body2" color="text.primary">
              {feedback}
            </Typography>
          </Paper>
        }

        {showEda && <Button size="small" fontSize="small" onClick={this.handleClickEda.bind(this)}>Chart</Button>}
        {/* {showEda && <QueryStatsIcon onClick={this.handleClickEda.bind(this)}></QueryStatsIcon>} */}

        {showEda && <Button onClick={this.handleClickDownload.bind(this)}>Save</Button>}
        {/* {showEda && <DownloadIcon onClick={this.handleClickDownload.bind(this)}></DownloadIcon>} */}


        {(this.treeId && treepts) && <Button onClick={this.handleClickTree.bind(this)}>Show my {ses.data.creature.type}</Button>}



        {this.context.isRoot && <Button onClick={this.handleDeleteClickOpen.bind(this)}>Delete</Button>}
        {/* {this.context.isRoot && <DeleteIcon onClick={this.handleDeleteClickOpen.bind(this)}></DeleteIcon>} */}

        <Dialog
          open={this.state.deleteDialogOpen}
          onClose={this.handleDeleteClose.bind(this)}
        >
          <DialogTitle id="alert-dialog-title">
            Are your sure you want to delete this session?
          </DialogTitle>
          {/* <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are your sure you want to delete this session?
            </DialogContentText>
          </DialogContent> */}
          <DialogActions>
            <Button onClick={this.deleteSession.bind(this)}>OK</Button>
            <Button onClick={this.handleDeleteClose.bind(this)} autoFocus>
              CANCEL            </Button>
          </DialogActions>
        </Dialog>

        <Backdrop
          sx={{ zIndex: 2, margin: 'auto', width: 1, height: 1 }}
          open={this.state.open}
          // onClick={this.handleClose.bind(this)}
        >
          {this.state.openwhat === "eda" &&
            <Paper elevation={5} sx={{ width: 0.95, height: 0.95 }}>
              <ResultChart
                data={ses}
              />
            </Paper>
          }

          {this.state.openwhat === "tree" &&
            <Paper elevation={5} sx={{ width: 0.95, height: 0.95 }}>
              <img style={{ width: "100%", height: "100%", objectFit: "contain", }} src={this.treeImg} />
            </Paper>
          }

          <div style={{ position: 'absolute', left: '0%', width: '100%', top: 20, zIndex: '100'}}>
                <Button variant="contained" onClick={this.handleClose.bind(this)}>Close</Button>
          </div>


        </Backdrop>

        {/* </CardContent> */}

      </Card>
    );

  }

}